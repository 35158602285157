import React from 'react';
import {
  Grid, Header, Label, List,
} from 'semantic-ui-react';
import parse from 'html-react-parser';
import History from './History';
import ConvertHelper from '../../../utils/ConvertHelper';
import MixpanelTracker from '../../../utils/MixpanelTracker';

const Content = ({ expert }) => {
  const allTags = [...expert.medical_specialties, ...expert.functions,
    ...expert.technology_tags, expert.organization.type].filter((x) => x);

  // TODO: Clearing data in those fields.
  // Handling bad data error for now
  const renderText = (html) => {
    if (!html) return '';
    try {
      return parse(html);
    } catch (err) {
      return `renderText Error: ${err.message}`;
    }
  };

  React.useEffect(() => {
    const hyperlinkNodes = document.getElementsByTagName('a');
    const elements = Object.values(hyperlinkNodes).filter((e) => e.innerText === 'LinkedIn');
    elements.forEach((e) => e.addEventListener('click', () => {
      MixpanelTracker.trackClickLinkedInLink(expert);
    }));
  }, []);

  return (
    <Grid container stackable>
      <Grid.Column width={10} className="detail">
        <Grid container>
          <Grid.Row>
            <Grid.Column>
              <Header>{`About ${expert.fullName}`}</Header>
              <div className="section">
                {renderText(ConvertHelper.richTextToHtml(expert.description))}
                {renderText(expert.infoFromLinkedin)}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header>Expert Tags</Header>
              {allTags.length > 0 && (
                <List className="tags-section">
                  {allTags.map((t) => <Label key={t} size="small">{t}</Label>)}
                </List>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={6} className="history">
        <Grid container>
          <Grid.Row>
            <Grid.Column>
              <Header>Meetings with my team</Header>
              <History expertId={expert.id} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
};

export default Content;
