import React from 'react';
import { Segment, Divider } from 'semantic-ui-react';
import ProjectDetails from './ProjectDetails';
import Scheduling from './Scheduling';

const AdvancedSettings = ({ project }) => (
  <Segment className="project-form">
    <strong className="project-title">Advanced Settings</strong>
    <Divider />
    <ProjectDetails project={project} />
    <Scheduling project={project} />
  </Segment>
);

export default AdvancedSettings;
