import React from 'react';
import { useQuery } from '@apollo/client';
import momentTZ from 'moment-timezone';
import {
  Grid, Segment, List, Tab,
  Header, Label, Button,
} from 'semantic-ui-react';
import Loading from '../Loading';
import SearchLabel from '../SearchLabel';
import ProfilePicture from '../Experts/ProfilePicture';
import ProfileLabel from '../ProfileLabel';
import BonusDisplay from '../Transactions/BonusDisplay';
import ProfileModal from '../Experts/ProfileModal';
import QualificationResponses from './QualificationResponses';
import MeetingModal from '../Meetings/MeetingModal';
import MeetingSettings from '../Meetings/MeetingSettings';
import ProjectShareModal from './ProjectShareModal';
import { PROJECT_JOBS } from '../../queries/project';
import Review from '../Review';
import CopyLinkButton from '../CopyLinkButton';
import { QUALTRICS_SURVEY_RESPONSE_URL } from '../../constants/qualtrics';

const ManageProjectJobs = ({ id, user }) => {
  const { loading, data } = useQuery(PROJECT_JOBS, {
    variables: { id },
  });

  if (loading) return <Loading />;

  const { project } = data;

  const COLOR = {
    'Pending Scheduling': 'yellow',
    'Client Reschedule': 'yellow',
    Scheduled: 'green',
    Completed: 'teal',
    Paid: 'teal',
    'No Show': 'red',
    'Expert No Show': 'red',
    'Client No Show': 'red',
  };

  const renderStatus = (status) => (
    <>
      <Label circular size="mini" color={COLOR[status]} empty />
      {` ${status}`}
    </>
  );

  const renderNoRequests = () => (
    <div className="not-found">
      <Header as="h2">No Requests Found</Header>
      {project.keywords.length > 0 && (
        <>
          <Header>
            Try to searching for experts with these keywords
          </Header>
          <List horizontal>
            {project.keywords.map(
              (tag) => (
                <List.Item key={tag}>
                  <SearchLabel query={tag} />
                </List.Item>
              ),
            )}
          </List>
          <p style={{ marginTop: '1rem' }}>
            <SearchLabel
              query={project.keywords.join(' ')}
              textDisplay="Search experts using all project keywords"
            />
          </p>
        </>
      )}
    </div>
  );

  const renderRequests = () => (
    <div className="requests">
      {
        project.jobs.map((job) => (
          <Segment key={job.id} className="request">
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Grid
                    stackable
                    doubling
                    verticalAlign="middle"
                    columns={5}
                  >
                    <Grid.Column
                      width={4}
                      className="heading"
                    >
                      <ProfileModal expertId={job.user.id}>
                        <div className="span-link">
                          <ProfilePicture expert={job.expert} />
                          <Header as="h3">
                            <Header.Content>
                              {job.user.fullName}
                              {project.team.name === 'Decibio' && (
                                <CopyLinkButton
                                  className="link-btn"
                                  copyText={job.user.email}
                                  copyPopMessage="Copied email address!"
                                  icon="mail"
                                />
                              )}
                              <Header.Subheader>
                                {`${job.expert.title} ${job.expert.organization.name}`}
                              </Header.Subheader>
                              <Header.Subheader>{job.expert.country}</Header.Subheader>
                              <ProfileLabel num={job.expert.credits} type="credit" />
                            </Header.Content>
                          </Header>
                        </div>
                      </ProfileModal>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      {job.qualificationResponses?.length > 0 && (
                      <QualificationResponses job={{ ...job, project }}>
                        <Button size="small" className="primary-btn">
                          Qualification response
                        </Button>
                      </QualificationResponses>
                      )}
                    </Grid.Column>
                    <Grid.Column width={3}>{renderStatus(job.overall_status)}</Grid.Column>
                    <Grid.Column width={3}>
                      {project.type === 'Interview' ? (
                        <MeetingModal
                          meeting={{ ...job, project }}
                          type={job.overall_status === 'Scheduled' ? 'Upcoming' : 'Past'}
                        >
                          <div className="span-link">
                            {job.meetingAt && momentTZ.tz(job.meetingAt, user.timeZone).format('ddd, M/D/YY hh:mm a z')}
                          </div>
                        </MeetingModal>
                      ) : job.qualtricsContact?.responseId && (
                        <Button
                          size="small"
                          className="basic-btn"
                          onClick={() => window.open(
                            `${QUALTRICS_SURVEY_RESPONSE_URL}RID=${job.qualtricsContact.responseId}&SID=${project.qualtricsSurvey.surveyId}`,
                            '_blank',
                          )}
                        >
                          View Survey Responses
                        </Button>
                      )}
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Review job={{ ...job, project }} />
                    </Grid.Column>
                    <Grid.Column width={1}>
                      <MeetingSettings job={{ ...job, project }} />
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="bonus">
                <Grid.Column>
                  {job.bonuses?.length > 0 && <BonusDisplay user={user} job={job} />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        ))
      }
    </div>
  );

  const projectLinkButton = (
    <ProjectShareModal project={data.project}>
      <Button className="basic-btn">Invite my contacts</Button>
    </ProjectShareModal>
  );

  return (
    <div id="manage-project-jobs">
      <div className="top-share-section">
        {projectLinkButton}
      </div>
      <Tab.Pane>
        {project.jobs.length === 0 ? renderNoRequests() : renderRequests()}
      </Tab.Pane>
      <div className="bottom-share-section">
        <Header size="large">
          Got experts?
        </Header>
        <div className="description">
          <Header.Subheader>
            Invite your own contacts to the project.
          </Header.Subheader>
          <Header.Subheader>
            Dexter will handle everything else - screening, scheduling, and payments
          </Header.Subheader>
        </div>
        {projectLinkButton}
      </div>
    </div>
  );
};

export default ManageProjectJobs;
