import { gql } from '@apollo/client';

export const TEAM_BASIC_INFO = `
  id
  name
  company
  phone
  availableInvites
  creditBalance
  pendingExpense
  perCreditPrice
  members {
    userId
    user {
      id
      email
    }
    role
  }
`;

const TEAM_PROFILE = gql`
  query Team($id: ID!) {
    team(id: $id) {
      ${TEAM_BASIC_INFO}
      projects {
        id
        status
      }
    }
  }
`;

export const ADD_MEMBER = gql`
  mutation AddMember($teamId: ID!, $email: String!, $role: String) {
    addMember(teamId: $teamId, email: $email, role: $role) {
      ${TEAM_BASIC_INFO}
    }
  }
`;

export const UPDATE_MEMBER = gql`
  mutation UpdateMember($teamId: ID!, $userId: ID!, $role: String) {
    updateMember(teamId: $teamId, userId: $userId, role: $role) {
      ${TEAM_BASIC_INFO}
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation DeleteMember($teamId: ID!, $userId: ID!) {
    deleteMember(teamId: $teamId, userId: $userId) {
      ${TEAM_BASIC_INFO}
    }
  }
`;

export const REQUEST_QUALTRICS_ACCESS = gql`
  mutation RequestQualtricsAccess($teamId: ID!) {
    requestQualtricsAccess(teamId: $teamId) {
      ${TEAM_BASIC_INFO}
    }
  }
`;

export default TEAM_PROFILE;
