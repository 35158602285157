import React, { useState } from 'react';
import { Header, Form, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';

const DATE_RANGE_OPTIONS = {
  AVAILABLE_DAYS: 'AvailableDays',
  DATE_RANGE: 'DateRange',
};

const DateRangeSelector = ({ dateRange, updateDateRange }) => {
  const [startDate, endDate] = dateRange;
  return (
    <Form>
      <Form.Field>
        <DatePicker
          selectsRange
          minDate={new Date()}
          startDate={startDate}
          endDate={endDate}
          onChange={updateDateRange}
          dateFormat="MMMM d, yyyy"
        />
      </Form.Field>
    </Form>
  );
};

const DateRange = ({ settings, setSettings }) => {
  const [rangeType, setRangeType] = useState(
    settings.startDate && settings.endDate
      ? DATE_RANGE_OPTIONS.DATE_RANGE
      : DATE_RANGE_OPTIONS.AVAILABLE_DAYS,
  );
  const [dateRange, setDateRange] = useState([settings.startDate, settings.endDate]);
  return (
    <div className="additional-setting">
      <Header>
        Date range
        <Header.Subheader>
          Experts can schedule...
        </Header.Subheader>
      </Header>
      <div className="available-days-option">
        <Form.Radio
          checked={rangeType === DATE_RANGE_OPTIONS.AVAILABLE_DAYS}
          onChange={(e, { checked }) => {
            if (checked) {
              setRangeType(DATE_RANGE_OPTIONS.AVAILABLE_DAYS);
              setSettings({ ...settings, startDate: null, endDate: null });
            }
          }}
        />
        <div className="option-label">
          <Input
            size="small"
            type="number"
            min={0}
            value={settings.availableDays}
            onChange={(e, { value }) => setSettings({ ...settings, availableDays: value })}
          />
          {' calendar days into the future.'}
        </div>
      </div>
      <div className="date-range-option">
        <Form.Radio
          checked={rangeType === DATE_RANGE_OPTIONS.DATE_RANGE}
          label="Within a date range"
          onChange={(e, { checked }) => {
            if (checked) {
              setRangeType(DATE_RANGE_OPTIONS.DATE_RANGE);
              setSettings({ ...settings, startDate: dateRange[0], endDate: dateRange[1] });
            }
          }}
        />
        {rangeType === DATE_RANGE_OPTIONS.DATE_RANGE && (
          <DateRangeSelector
            dateRange={dateRange}
            updateDateRange={(dates) => {
              setDateRange(dates);
              setSettings({ ...settings, startDate: dates[0], endDate: dates[1] });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default DateRange;
