import { gql } from '@apollo/client';
import { PROJECT_JOB } from './job';

export const PROJECT_BASIC_DATA = `
  id
  type
  code
  title
  status
  description
  createdAt
  updatedAt
  keywords
  targetN
  surveyLink
  schedulingSettings {
    attendanceRequirement
    leadHours
    bufferMinutes
    availableDays
    startDate
    endDate
  }
  preInterviewLink
  owners {
    id
    email
  }
  qualtricsSurvey {
    surveyId
    status
    qualtricsUsers {
      username
      password
    }
  }
  qualificationQuestions {
    id
    question
    questionType
    answerChoices
    otherIncluded
  }
  numUpcomingMeetings
  numPendingInvites
  numCompleted
  deletable
`;

const PROJECT = gql`
  query Project($id: String!) {
    project(id: $id) {
      ${PROJECT_BASIC_DATA}
    }
  }
`;

export const PROJECTS = gql`
  query Projects($query: String, $filters: ProjectFilters!, $pagination: Pagination) {
    projects(query: $query, filters: $filters, pagination: $pagination) {
      total
      projects {
        ${PROJECT_BASIC_DATA}
      }
    }
  }
`;

export const PROJECT_JOBS = gql`
  query Project($id: String!) {
    project(id: $id) {
      id
      type
      title
      description
      keywords
      team {
        id
        name
      }
      owners {
        id
      }
      preInterviewLink
      qualtricsSurvey {
        surveyId
      }
      jobs {
        ${PROJECT_JOB}
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectInput!) {
    createProject(input: $input) {
      ${PROJECT_BASIC_DATA}
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: ProjectInput!) {
    updateProject(id: $id, input: $input) {
      ${PROJECT_BASIC_DATA}
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;

export const COMPLETE_PROJECT = gql`
  mutation completeProject($id: ID!) {
    completeProject(id: $id) {
      id
      status
    }
  }
`;

export default PROJECT;
