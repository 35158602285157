import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Loader } from 'semantic-ui-react';
import PROJECT from '../../../queries/project';
import Details from './Details';
import Owners from './Owners';
import InterviewSettings from './InterviewSettings';
import AdvancedSettings from './AdvancedSettings';
import Activity from './Activity';
import CompleteProjectButton from '../CompleteProjectButton';

const ViewProject = ({ id }) => {
  const { loading, data } = useQuery(PROJECT, {
    variables: { id },
  });

  if (loading) return <Loader active />;

  return (
    <Grid id="view-project">
      <Grid.Column>
        <Grid.Row className="actions">
          <CompleteProjectButton project={data.project} />
        </Grid.Row>
        <Grid.Row>
          <Details project={data.project} />
        </Grid.Row>
        <Grid.Row>
          <Owners project={data.project} />
        </Grid.Row>
        {data.project.type === 'Interview' && (
          <>
            <Grid.Row>
              <InterviewSettings project={data.project} />
            </Grid.Row>
            <Grid.Row>
              <AdvancedSettings project={data.project} />
            </Grid.Row>
          </>
        )}
        <Grid.Row>
          <Activity project={data.project} />
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

export default ViewProject;
