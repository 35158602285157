import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import ViewProject from '../components/Projects/ViewProject';
import ManageProjectJobs from '../components/Projects/ManageProjectJobs';
import TrackProjectProgress from '../components/Projects/TrackProjectProgres';
import DataContext from '../contexts/DataContext';

const ProjectDetail = () => {
  const { id } = useParams();
  const { user } = useContext(DataContext);

  const panes = [
    { menuItem: 'View project', render: () => <ViewProject id={id} /> },
    { menuItem: 'Manage experts', render: () => <ManageProjectJobs id={id} user={user} /> },
    { menuItem: 'Track progress', render: () => <TrackProjectProgress id={id} /> },
  ];

  return (
    <div id="project-detail">
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </div>
  );
};

export default ProjectDetail;
