import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import ConvertHelper from './ConvertHelper';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

class MixpanelTracker {
  static #isLoginTracked = false;

  static #getProfileData(expert, searchContext) {
    const {
      searchResult, query, appliedFilters, page,
    } = searchContext;
    const getFilters = (categoryId) => {
      const filterObj = appliedFilters.filter((obj) => obj.categoryId === categoryId)[0];
      return filterObj ? filterObj.values : [];
    };
    // only track the profile data from search
    const plainInfoFromLinkedin = ConvertHelper.stripHtml(expert.infoFromLinkedin);
    let plainDescription = ConvertHelper.richTextToPlainText(expert.description);
    if (plainDescription.includes('richTextToPlainText Error')) {
      plainDescription = '';
    }
    const expertTags = [
      ...expert.functions,
      ...expert.medical_specialties,
      ...expert.technology_tags,
      expert.organization.type,
    ];
    const numOfKeywordMatches = ConvertHelper.countKeywordOccurences(
      plainInfoFromLinkedin + plainDescription + expertTags.join(' ') + expert.fullName + expert.organization.name + expert.title,
      query,
    );
    const expertIndex = searchResult?.experts.map((e) => e.id).indexOf(expert.id);
    const expertTeamRatings = expert.user.pastMeetings.filter(
      (j) => j.expert_rating && j.expert_rating !== 0,
    );
    const numOfExpertTeamRatings = expertTeamRatings.length;
    const expertTeamAvgRating = numOfExpertTeamRatings > 0
      ? expertTeamRatings.reduce((sum, r) => sum + r.expert_rating, 0) / expertTeamRatings.length
      : 0;
    const appliedFilterValues = [].concat(...appliedFilters.map((obj) => obj.values));
    return {
      'Expert ID': expert.id,
      'Expert Name': expert.fullName,
      'Expert Title': expert.title,
      'Expert Organization': expert.organization.name,
      'Expert Country': expert.country,
      'Expert Tags': expertTags,
      '# of Expert Tags': expertTags.length,
      'Expert Credit Amount': expert.credits,
      'Expert Completed Calls': expert.numCompleted,
      'Expert Profile Picture': expert.profile_picture !== null && expert.profile_picture !== '',
      '# of Keyword Matches': numOfKeywordMatches,
      'Expert Profile Total Length': plainInfoFromLinkedin.length + plainDescription.length,
      'Expert Profile InfoFromLinkedin Length': plainInfoFromLinkedin.length,
      'Expert Profile Description Length': plainDescription.length,
      'Expert Linkedin Present': plainInfoFromLinkedin.includes('View LinkedIn'),
      'Expert Publications Present': plainInfoFromLinkedin !== '' && !plainInfoFromLinkedin.toLowerCase().includes('no publications found'),
      '# of Expert Team Pending Invitations': expert.user.pendingInvites?.length,
      '# of Expert Upcoming Team Meetings': expert.user.upcomingMeetings?.length,
      '# of Expert Past Team Meetings': expert.user.pastMeetings?.length,
      '# of Expert Team Ratings': numOfExpertTeamRatings,
      'Expert Team Rating Average': expertTeamAvgRating,
      'Expert Profile Ranking': expertIndex + 6 * (page - 1) + 1,
      'Page Number': page,
      'Search Root': query,
      '# of All Applied Filters': appliedFilterValues.length,
      'All Applied Filters': appliedFilterValues,
      'Country Filters': getFilters('country'),
      'Job Title Filters': getFilters('title'),
      'Organization Type Filters': getFilters('organization.type'),
      'Medical Specialties Filters': getFilters('medical_specialties'),
      'Technology Filters': getFilters('technology_tags'),
    };
  }

  static trackSignup(user, team) {
    mixpanel.track('Sign Up');
    mixpanel.people.set({
      'Email Address': user.email,
      'Registration Date': moment(user.createdAt).format('l'),
      'Company Name': team.company,
      'Team Name': team.name,
    });
  }

  static trackLogin(user, team) {
    if (!this.#isLoginTracked) {
      mixpanel.track('Log In');
      mixpanel.people.set_once({ 'First Login': moment(new Date()).format('l') });
      mixpanel.people.set({
        'Last Login': moment(new Date()).format('l'),
        '# of Upcoming Meetings': user.upcomingMeetings?.length,
        '# of Pending Invites': user.pendingInvites?.length,
        '# of Available Credits': team.creditBalance,
      });
      mixpanel.people.increment('# of Logins', 1);
    }
    this.#isLoginTracked = true;
  }

  static trackSearch(query, total) {
    mixpanel.track('Search', {
      'Search Term': query,
      '# of Results Returned': total,
    });
  }

  static trackViewExpertProfile(expert, searchContext) {
    const profileData = this.#getProfileData(expert, searchContext);
    mixpanel.track('View Expert Profile', profileData);
  }

  static trackClickLinkedInLink(expert) {
    mixpanel.track('Click LinkedIn Link', {
      'Expert ID': expert.id,
      'Expert Name': expert.fullName,
    });
  }

  static trackOpenInviteModal(expert) {
    mixpanel.track('Open Invite Modal', {
      'Expert ID': expert.id,
      'Expert Name': expert.fullName,
    });
  }

  static trackSelectProject(expert, project) {
    mixpanel.track('Select Project in the Invite Modal', {
      'Project ID': project.id,
      'Project Name': project.title,
      'Expert ID': expert.id,
      'Expert Name': expert.fullName,
    });
  }

  static trackSendInvite(user, team, expert, project, searchContext) {
    const profileData = this.#getProfileData(expert, searchContext);
    mixpanel.track('Send Invite', {
      ...profileData,
      'Project Name': project.title,
      'Project Keywords': project.keywords,
      'Project ID': project.id,
      'Project # of Pending Invites': project.numPendingInvites,
      '# of Upcoming Meetings': user.upcomingMeetings?.length,
      '# of Pending Invites': user.pendingInvites?.length,
      '# of Available Credits': team.creditBalance,
      '# of Available Invites': team.availableInvites,
    });
    mixpanel.people.set({
      '# of Upcoming Meetings': user.upcomingMeetings?.length,
      '# of Pending Invites': user.pendingInvites?.length,
      '# of Available Credits': team.creditBalance,
      '# of Available Invites': team.availableInvites,
    });
  }
}

export const mixpanelClient = mixpanel;

export default MixpanelTracker;
