import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import Loading from '../Loading';
import { UPCOMING_MEETINGS } from '../../queries/profile';
import DataContext from '../../contexts/DataContext';

const ProfileStats = () => {
  const { team } = useContext(DataContext);
  const { loading, data } = useQuery(UPCOMING_MEETINGS);

  if (loading) return <Loading />;

  return (
    <Grid doubling columns={4} className="profile-stats">
      <Grid.Row>
        <Grid.Column width={6}>
          <div className="stats-item">
            <div className="label">
              <Icon name="calendar outline" />
              Upcoming Meetings
            </div>
            <div className="number">{data.me.upcomingMeetings.length}</div>
            <div className="footer empty-spacing" />
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <div className="stats-item">
            <div className="label">
              <Icon name="database" />
              Available Credits
            </div>
            <div className="number">{team.creditBalance - team.pendingExpense}</div>
            <div className={`footer ${team.pendingExpense === 0 && 'empty-spacing'}`}>
              {team.pendingExpense > 0 && `${team.pendingExpense} credit(s) on hold for scheduled meetings`}
            </div>
          </div>
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <div className="credit-link">
            <Link to="/settings/credits">
              View credits history
              <Icon name="arrow right" />
            </Link>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ProfileStats;
