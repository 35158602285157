import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Popup, Icon } from 'semantic-ui-react';
import ActionConfirm from './ActionConfirm';
import { COMPLETE_PROJECT } from '../../queries/project';
import DataContext from '../../contexts/DataContext';
import MessageContext from '../../contexts/MessageContext';

const CompleteProjectButton = ({ project }) => {
  const { managedProjectsRefetch } = useContext(DataContext);
  const { setSuccessPopup } = useContext(MessageContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [completeProject] = useMutation(COMPLETE_PROJECT, {
    onCompleted: () => {
      managedProjectsRefetch();
      setSuccessPopup({
        title: `${project.title} has been marked complete`,
        content: `
          The project status has been updated to "Completed",
          and all pending invitations have been withdrawn.
          You can still view the project but cannot make any edits or invite new experts to the project
        `,
      });
    },
  });

  if (project.numUpcomingMeetings > 0) {
    return (
      <Popup
        content="This project cannot be marked complete until the scheduled invites are complete"
        trigger={<Button size="small" floated="right" className="basic-btn disabled-btn">Mark Complete</Button>}
      />
    );
  }

  const isCompleted = project.status === 'Completed';

  return (
    <>
      {confirmOpen && (
        <ActionConfirm
          projectTitle={project.title}
          onCancel={() => setConfirmOpen(false)}
          onAction={() => completeProject({ variables: { id: project.id } })}
          type="complete"
        />
      )}
      <Popup
        content={!isCompleted && 'Ready to wrap up this project? Mark it as complete to close it out'}
        trigger={(
          <Button
            floated="right"
            size="small"
            disabled={isCompleted}
            className="basic-btn"
            onClick={() => setConfirmOpen(true)}
          >

            {isCompleted && <Icon name="check" />}
            {isCompleted ? 'Completed' : 'Mark Complete'}
          </Button>
)}
      />
    </>
  );
};

export default CompleteProjectButton;
