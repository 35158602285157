import { gql } from '@apollo/client';
import { PROJECT_BASIC_DATA } from './project';
import { MEETING_BASIC_INFO } from './meeting';
import { EXTERNAL_ACCOUNT_BASIC_DATA } from './externalAccount';

const PROFILE_BASIC_DATA = `
  id
  firstName
  lastName
  fullName
  email
  timeZone
  settings {
    name
    value
  }
  availability {
    dayOfWeek
    startTime
    endTime
  }
  membership {
    teamId
    teamName
    role
  }
  externalAccounts {
    ${EXTERNAL_ACCOUNT_BASIC_DATA}
  }
`;

const ME_PROFILE = gql`
  {
    me {
      ${PROFILE_BASIC_DATA}
      upcomingMeetings {
        id
      }
      pendingInvites {
        id
      }
      pastMeetings {
        id
      }
    }
  }
`;

export const MANAGED_PROJECTS = gql`
  {
    me {
      id
      managedProjects {
        ${PROJECT_BASIC_DATA}
        jobs {
          user {
            id
          }
        }
      }
    }
  }
`;

export const UPCOMING_MEETINGS = gql`
  {
    me {
      id
      upcomingMeetings {
        ${MEETING_BASIC_INFO}
      }
    }
  }
`;

export const PAST_MEETINGS = gql`
  {
    me {
      id
      pastMeetings {
        ${MEETING_BASIC_INFO}
      }
    }
  }
`;

export const ALL_MEETINGS = gql`
  {
    me {
      id
      upcomingMeetings {
        ${MEETING_BASIC_INFO}
      }
      pastMeetings {
        ${MEETING_BASIC_INFO}
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser ($input: UserInput) {
    updateUser(input: $input) {
      ${PROFILE_BASIC_DATA}
      upcomingMeetings {
        id
      }
      pendingInvites {
        id
      }
      pastMeetings {
        id
      }
    }
  }
`;

export const UPDATE_SETTING = gql`
  mutation UpdateSetting ($name: String!, $value: String!) {
    updateSetting (name: $name, value: $value) {
      ${PROFILE_BASIC_DATA}
    }
  }
`;

export default ME_PROFILE;
