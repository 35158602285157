import moment from 'moment-timezone';

class ProjectForecastHelper {
  /**
   * Only one value can be selected per category
   * @typedef {Object.<string, string>} CategoryFilters
   */
  /**
   * @typedef {Object} ResponseForecast
   * @property {string} name - The name of the value of the filter category (optional property)
   * @property {number} numCompleted - The number of completed jobs.
   * @property {number} numScheduled - The number of jobs scheduled for the future.
   * @property {number} numPredicted - The predicted number of jobs based on accept probabilities.
   * @property {number} numResponses - The total number of forecasted jobs.
   */

  /**
   * List jobs under each filter based on a filter category
   *
   * @param {Array.<Job>} jobs - The array of jobs.
   * @param {String} filterCategory - The filter category.
   * @returns {Object.<string, Array.<Job>>} - The jobs belonged to the specified filter value
   */
  static #listJobsByCategoryFilters(jobs, filterCategory) {
    const categoryFields = filterCategory.split('.');
    return jobs.reduce((acc, job) => {
      const expertCategoryValue = categoryFields.reduce(
        (innerAcc, field) => (innerAcc && innerAcc[field] ? innerAcc[field] : null), job.expert,
      );

      // Case for technology_tags and medical_specialties
      if (Array.isArray(expertCategoryValue)) {
        // Make each element as a key
        expertCategoryValue.forEach((v) => {
          acc[v] = acc[v] || [];
          acc[v].push(job);
        });
      } else {
        acc[expertCategoryValue] = acc[expertCategoryValue] || [];
        acc[expertCategoryValue].push(job);
      }
      return acc;
    }, {});
  }

  /**
   * Get the filtered jobs based on the specified criteria.
   *
   * @param {Array.<Job>} jobs - The array of jobs to filter.
   * @param {CategoryFilters} categoryFilters - The filter criteria for jobs.
   * @returns {Array.<Job>} - The filtered array of jobs.
   */
  static #getFilteredJobs(jobs, categoryFilters) {
    return jobs.filter((job) => Object.keys(categoryFilters).every((category) => {
      const fields = category.split('.');
      const expertCategoryValue = fields.reduce(
        (acc, field) => (acc && acc[field] ? acc[field] : null), job.expert,
      );

      if (Array.isArray(expertCategoryValue)) {
        return expertCategoryValue.includes(categoryFilters[category]);
      }
      return expertCategoryValue === categoryFilters[category];
    }));
  }

  /**
   * Calculates the response forecast data based on the provided job data and current date.
   *
   * @param {Array.<Job>} jobs - The array of jobs.
   * @param {number} daysFromNow - The days difference between today and the date to forecast.
   * @param {string} viewerTimeZone - Viewer's timezone
   * @returns {ResponseForecast} - An object containing the response forecast metrics.
   */
  static getResponseForecast(jobs, daysFromNow, viewerTimeZone) {
    const endOfToday = moment().tz(viewerTimeZone).endOf('day');
    const forecastDate = moment(endOfToday).add(daysFromNow, 'days');
    const numCompleted = jobs.filter((j) => j.overall_status === 'Completed'
    || (j.overall_status === 'Scheduled' && moment(j.meetingAt).isBefore(forecastDate))).length;
    const numScheduled = jobs.filter((j) => j.overall_status === 'Scheduled' && moment(j.meetingAt).isSameOrAfter(forecastDate)).length;
    const pendingJobs = jobs.filter((j) => ['Pending Scheduling', 'Client Reschedule'].includes(j.overall_status));

    const predictProbability = pendingJobs.reduce((sum, j) => sum + j.acceptProbabilities
      .slice(0, daysFromNow + 1)
      .reduce((daySum, probability) => daySum + probability, 0), 0);
    const numPredicted = Math.round(predictProbability);

    return {
      numCompleted,
      numScheduled,
      numPredicted,
      numResponses: numCompleted + numScheduled + numPredicted,
    };
  }

  /**
   * Calculates the response forecast for each filter value based on applied filters.
   *
   * @param {Array.<Job>} jobs - The array of jobs.
   * @param {number} daysFromNow - The days difference between today and the date to forecast.
   * @param {string} viewerTimeZone - Viewer's timezone.
   * @param {CategoryFilters} categoryFilters - The filter criteria for jobs.
   * @param {string} filterCategory - The filter category.
   * @returns {Array.<ResponseForecast>} - An array of response forecast.
   */
  static getFilterValuesWithResponseForecast(
    jobs, daysFromNow, viewerTimeZone, categoryFilters, filterCategory,
  ) {
    // Get list of filter values and the jobs belonged to each value, from a given filter category
    const filterValuesAndJobs = this.#listJobsByCategoryFilters(jobs, filterCategory);
    const filterValues = Object.keys(filterValuesAndJobs);

    // Create a list that contains each filter value with its response forecast metric
    const filterValuesWithResponseForecast = filterValues.map((filterValue) => {
      const filteredJobs = this.#getFilteredJobs(filterValuesAndJobs[filterValue], categoryFilters);
      const responseForecast = this.getResponseForecast(filteredJobs, daysFromNow, viewerTimeZone);
      return { name: filterValue, ...responseForecast };
    });
    filterValuesWithResponseForecast.sort((a, b) => b.numResponses - a.numResponses);
    return filterValuesWithResponseForecast;
  }
}

export default ProjectForecastHelper;
