import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Icon, Dropdown, Popup } from 'semantic-ui-react';
import DataContext from '../../../contexts/DataContext';
import RescheduleMeeting from '../RescheduleMeeting';
import CancelJob from '../CancelJob';
import CancelRequest from './CancelRequest';
import ReportNoShow from '../ReportNoShow';
import PayBonusModal from '../../Transactions/PayBonusModal';
import { MEETING_INVITE } from '../../../queries/meeting';
import addToCalendar from '../../../utils/addToCalendar';
import Security from '../../../utils/Security';

const FIVE_HOURS_AGO = new Date(new Date().getTime() - 5 * 60000);

const MeetingSettings = ({ job }) => {
  const { user } = useContext(DataContext);
  const { data } = useQuery(MEETING_INVITE, {
    variables: { id: job.id },
  });
  const handleAddToCalendar = () => {
    addToCalendar({ job: { ...job, meetingInvite: data.job.meetingInvite }, user });
  };
  const isProjectOwner = Security.isProjectOwner(job.project);
  const isDecibio = user.membership.map((x) => x.teamName).includes('Decibio');

  const renderPayBonusButton = () => {
    if (job.expert.stripe_account) {
      return (
        <PayBonusModal job={job}>
          <Dropdown.Item as="a">
            Pay bonus
          </Dropdown.Item>
        </PayBonusModal>
      );
    }
    return (
      <Dropdown.Item className="disabled-btn">
        Pay bonus
      </Dropdown.Item>
    );
  };

  const renderScheduledOptions = () => {
    if (job.meetingAt) {
      if (job.meetingAt < FIVE_HOURS_AGO) {
        return (
          <ReportNoShow job={job}>
            <Dropdown.Item as="a">Report a no show</Dropdown.Item>
          </ReportNoShow>
        );
      }
      if (job.meetingAt > new Date()) {
        return (
          <>
            <Dropdown.Item as="a" onClick={handleAddToCalendar}>Add to calendar</Dropdown.Item>
            {isProjectOwner && (
              <>
                <CancelJob job={job}>
                  <Dropdown.Item as="a">Cancel this meeting</Dropdown.Item>
                </CancelJob>
                <RescheduleMeeting job={job}>
                  <Dropdown.Item as="a">Reschedule this meeting</Dropdown.Item>
                </RescheduleMeeting>
              </>
            )}
          </>
        );
      }
    }
    return (
      <CancelJob job={job}>
        <Dropdown.Item as="a">Cancel this invite</Dropdown.Item>
      </CancelJob>
    );
  };

  return (
    <Dropdown
      trigger={<Icon id="ellipsis-menu" name="ellipsis vertical" />}
      pointing="top right"
      id="meeting-settings"
    >
      <Dropdown.Menu onKeyDown={(e) => e.stopPropagation()}>
        {(job.overall_status === 'Pending Scheduling' || job.overall_status === 'Client Reschedule') && (
          <CancelRequest job={job}>
            <Dropdown.Item as="a">Cancel this request</Dropdown.Item>
          </CancelRequest>
        )}
        {job.overall_status === 'Scheduled' && renderScheduledOptions()}
        {/* Remove isDecibio if we want to allow clients to make bonus payments */}
        {isDecibio && (
          <Popup
            disabled={!!job.expert.stripe_account}
            content="Bonus is only available to Stripe-connected experts"
            trigger={renderPayBonusButton()}
          />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MeetingSettings;
