import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';
import DataContext from '../contexts/DataContext';
import { FOLLOWED_LISTS, TEAM_LISTS } from '../queries/list';
import ListExperts from '../components/Lists/ListExperts';
import Loading from '../components/Loading';
import { LIST_PRIVACY_OPTIONS } from '../constants/lists';

const List = () => {
  const history = useHistory();
  const { id } = useParams();
  const { myLists } = useContext(DataContext);
  const { loading: loadingFollowedLists, data: followedData } = useQuery(FOLLOWED_LISTS);
  const { loading: loadingTeamLists, data: teamData } = useQuery(TEAM_LISTS);

  if (loadingFollowedLists || loadingTeamLists) return (<Loading />);

  const listGroups = {
    'Your lists': myLists,
    'Lists you follow': followedData.followedLists,
    'Lists by teammates': teamData.teamLists,
  };

  const renderListLink = (list) => (
    <Menu.Item
      key={list.id}
      name={list.name}
      active={list.id === id}
      icon={LIST_PRIVACY_OPTIONS[list.privacy].icon}
      onClick={() => history.push(`/lists/${list.id}`)}
    />
  );

  const renderllistGroup = (label, lists) => {
    if (lists.length) {
      return (
        <Menu.Item key={label}>
          <Menu.Header>
            {`${label}:`}
          </Menu.Header>
          <Menu.Menu>
            {lists.map((list) => renderListLink(list))}
          </Menu.Menu>
        </Menu.Item>
      );
    }
    return null;
  };

  return (
    <Grid id="lists" stackable>
      <Grid.Row>
        <Grid.Column width={4}>
          <Menu pointing vertical fluid size="huge">
            {Object.keys(listGroups).map((label) => renderllistGroup(label, listGroups[label]))}
          </Menu>
        </Grid.Column>
        <Grid.Column width={12}>
          <ListExperts id={id} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default List;
