import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Segment, Button, Header, Divider, Form, Radio, Message,
} from 'semantic-ui-react';
import { UPDATE_PROJECT } from '../../../queries/project';
import CustomLink from '../../CustomLink';

const SCHEDULING_OPTION = {
  'All must attend': (
    <div className="text">
      {'Allow Experts to schedule only when '}
      <u>all project owners</u>
      {' are available.'}
    </div>
  ),
  'At least one': (
    <div className="text">
      {'Allow Experts to schedule when '}
      <u>at least 1 project owner</u>
      {' is available.'}
    </div>
  ),
};

const SchedulingOption = ({ project }) => {
  const history = useHistory();
  const [schedulingOption, setSchedulingOption] = useState(
    project.schedulingSettings.attendanceRequirement,
  );
  const [success, setSuccess] = useState(false);

  const [updateProject, { loading }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => setSuccess(true),
  });

  return (
    <Form
      className="interview-settings"
      onSubmit={() => updateProject({
        variables: { id: project.id, input: { schedulingOption } },
      })}
    >
      {success && (
        <Message positive onDismiss={() => setSuccess(false)}>
          Successfully updated !
        </Message>
      )}
      <Header>
        Scheduling Option
        <Header.Subheader>Choose when an expert can schedule a meeting</Header.Subheader>
      </Header>
      <Form.Group grouped>
        {Object.keys(SCHEDULING_OPTION).map((option) => (
          <Form.Field key={option} className="scheduling-option">
            <Radio
              value={option}
              checked={schedulingOption === option}
              onChange={(e, { value }) => setSchedulingOption(value)}
            />
            <div className="label">{SCHEDULING_OPTION[option]}</div>
          </Form.Field>
        ))}
      </Form.Group>
      <Header as="h5">
        {`
            Note: Project owners' availability defaults to 9:00am - 5:00pm weekdays (in your local time).
            Make sure all owners update their availability
          `}
        <CustomLink onClick={() => history.replace('/settings/availability/available_time_blocks')}>
          here.
        </CustomLink>
      </Header>
      {project.status !== 'Completed' && (
        <Button className="primary-btn" size="tiny" loading={loading} disabled={loading}>
          Save
        </Button>
      )}
    </Form>
  );
};

const InterviewSettings = ({ project }) => (
  <Segment className="project-form">
    <strong className="project-title">Interview Settings</strong>
    <Divider />
    <SchedulingOption project={project} />
  </Segment>
);

export default InterviewSettings;
