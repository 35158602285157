import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import {
  Modal, Form, Button, Header, Confirm,
} from 'semantic-ui-react';
import DataContext from '../../contexts/DataContext';
import MessageContext from '../../contexts/MessageContext';
import { PAY_EXPERT } from '../../queries/transaction';

const BONUS_PAYMENT_DEFAULT = {
  type: 'Bonus Payment', method: 'Stripe', amount: 0, description: '',
};
const BONUS_MARKUP = 1.05;
const MAXIMUM_BONUS_AMOUNT = 250;

const PayBonusModal = ({ job, children }) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [payment, setPayment] = useState(BONUS_PAYMENT_DEFAULT);
  const [credits, setCredits] = useState(0);
  const { setErrorPopup } = useContext(MessageContext);
  const { team } = useContext(DataContext);
  const { perCreditPrice } = team;

  const amountToCredits = (amount) => {
    setCredits(Math.ceil((amount * BONUS_MARKUP * 100) / perCreditPrice) / 100);
  };

  const resetPayment = () => {
    setPayment(BONUS_PAYMENT_DEFAULT);
    setCredits(0);
  };

  const onClose = () => {
    setOpen(false);
    resetPayment();
  };

  const [payExpert, { loading }] = useMutation(PAY_EXPERT, {
    onCompleted: () => {
      setOpen(false);
      resetPayment();
    },
    onError: (err) => {
      setErrorPopup({
        title: 'Failed to make bonus payment',
        content: err.message,
        onClose: () => setOpen(false),
      });
    },
  });

  return (
    <>
      <Confirm
        open={confirmOpen}
        confirmButton="I understand. Make payment"
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false);
          payExpert({ variables: { jobId: job.id, payment } });
        }}
        header={`You are about to pay a $${payment.amount} bonus to ${job.user.fullName}.`}
        content={(
          <Header>
            {`${credits} credits will be immediately deducted from your account.`}
            <br />
            Once paid, a bonus can not be rescinded.
            <br />
            Are you sure you want to make this bonus payment?
          </Header>
        )}
      />
      <Modal
        closeIcon
        open={open}
        onOpen={() => setOpen(true)}
        onClose={onClose}
        trigger={children}
        className="setting-modal-layout"
      >
        <Modal.Header>Bonus Payment</Modal.Header>
        <Modal.Content>
          <Header>
            {`Pay a bonus to ${job.user.fullName}`}
          </Header>
          <Form>
            <Form.Input
              label="Reason"
              type="text"
              placeholder="Provide a reason for this bonus payment"
              value={payment.description}
              onChange={(e, { value }) => {
                setPayment({ ...payment, description: value });
              }}
            />
            <Form.Input
              label="Amount $"
              type="number"
              min={0}
              max={MAXIMUM_BONUS_AMOUNT}
              value={payment.amount}
              error={payment.amount > MAXIMUM_BONUS_AMOUNT
                && { content: `Payment must be less than ${MAXIMUM_BONUS_AMOUNT}` }}
              onChange={(e, { value }) => {
                let amount = value;
                if (amount !== '') {
                  amount = parseInt(amount, 10);
                  amountToCredits(amount);
                } else {
                  amountToCredits(0);
                }
                setPayment({ ...payment, amount });
              }}
            />
            <Header>
              {`Credits: ${credits}`}
            </Header>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <span className="subtext">
            Note: bonus payments are immediately paid and deducted from your available credits
          </span>
          <Button className="basic-btn" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={!payment.amount || !payment.description || loading}
            className="primary-btn"
            onClick={() => setConfirmOpen(true)}
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
    </>

  );
};

export default PayBonusModal;
