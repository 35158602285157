import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Accordion,
  Icon,
  Button,
  Message,
  Header,
  Transition,
} from 'semantic-ui-react';
import LeadTime from './LeadTime';
import DateRange from './DateRange';
import BufferTime from './BufferTime';
import { UPDATE_PROJECT } from '../../../../queries/project';

const DEFAULT_SCHEDULING_SETTINGS = {
  leadHours: 18,
  bufferMinutes: 30,
  availableDays: 21,
  startDate: null,
  endDate: null,
};

const nonNullObjectMerge = (defaultObject, newObject) => {
  const mergedObject = { ...defaultObject };
  Object.keys(newObject).forEach((key) => {
    // To avoid overwriting the default value with null
    if (newObject[key] !== null) {
      mergedObject[key] = newObject[key];
    }
  });
  return mergedObject;
};

const Scheduling = ({ project }) => {
  const [open, setOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [settings, setSettings] = useState(
    nonNullObjectMerge(DEFAULT_SCHEDULING_SETTINGS, project.schedulingSettings),
  );
  const [updateProject, { loading, error }] = useMutation(UPDATE_PROJECT, {
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(() => setIsSaved(false), 3000);
    },
  });
  return (
    <Accordion>
      <Accordion.Title
        active={open}
        onClick={() => setOpen(!open)}
      >
        Additional interview settings
        <Icon name="dropdown" />
      </Accordion.Title>
      <Accordion.Content active={open}>
        <div className="accordion-content">
          {error && <Message error={error !== null} content={error.message} />}
          <LeadTime settings={settings} setSettings={setSettings} />
          <DateRange
            settings={settings}
            setSettings={setSettings}
          />
          <BufferTime settings={settings} setSettings={setSettings} />
          {isSaved && (
          <Transition visible={isSaved} animation="scale" duration={500}>
            <Header color="green">Successfully saved!</Header>
          </Transition>
          )}
          <Button
            className="primary-btn"
            loading={loading}
            disabled={loading}
            onClick={() => updateProject({
              variables: {
                id: project.id,
                input: {
                  schedulingSettings: {
                    leadHours: parseInt(settings.leadHours, 10),
                    bufferMinutes: parseInt(settings.bufferMinutes, 10),
                    availableDays: parseInt(settings.availableDays, 10),
                    startDate: settings.startDate,
                    endDate: settings.endDate,
                  },
                },
              },
            })}
          >
            Save
          </Button>
        </div>
      </Accordion.Content>
    </Accordion>
  );
};

export default Scheduling;
