import React from 'react';
import { Confirm, Header, Button } from 'semantic-ui-react';

const ActionConfirm = ({
  projectTitle, onCancel, onAction, type,
}) => (
  <Confirm
    open
    closeIcon
    header={type === 'complete' ? 'Mark Project Complete' : 'Delete Project'}
    content={(
      <div style={{ padding: '1.5rem' }}>
        <Header>
          {`Are you sure you want to
            ${type === 'complete' ? 'mark' : 'delete'}
            ${projectTitle}${type === 'complete' ? ' complete' : ''}?
          `}
        </Header>
        <p>
          {
            type === 'complete'
              ? `
                By marking this project complete,
                you acknowledge that you are done with this project.
                This will withdraw any pending invitations that have not be fulfilled
                and you will no longer be able to invite new experts to this project.
              ` : `
                The will remove the project permanently from your dashboard.
                Once deleted, the project cannot be retrieved.
              `
          }
        </p>
        <p>This action cannot be undone</p>
      </div>
    )}
    onCancel={onCancel}
    onConfirm={() => {
      onAction();
      onCancel();
    }}
    cancelButton={<Button className="basic-btn">Cancel</Button>}
    confirmButton={<Button className="primary-btn">{type === 'complete' ? 'Mark Complete' : 'Delete'}</Button>}
  />
);

export default ActionConfirm;
