import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { List, Rating } from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import MeetingModal from '../../Meetings/MeetingModal';
import ReviewModal from '../../Meetings/ReviewModal';
import CustomLink from '../../CustomLink';
import Loading from '../../Loading';
import { EXPERT_MEETING_HISTORY } from '../../../queries/expert';
import DataContext from '../../../contexts/DataContext';

const MeetingList = ({ title, meetings }) => {
  const { user } = useContext(DataContext);

  const renderTitle = (job) => {
    if (title === 'Upcoming') {
      return (
        <MeetingModal meeting={job} type="Upcoming">
          <CustomLink>
            {job.project.title}
          </CustomLink>
        </MeetingModal>
      );
    }
    return <strong>{job.project.title}</strong>;
  };

  const renderTime = (job) => {
    const timeFormat = 'h:mm a, MM/DD/YY z';
    const time = momentTZ.tz(job.meetingAt, user.timeZone).format(timeFormat);
    const interviewers = job.interviewers?.map((interviewer) => {
      if (interviewer.id === user.id) {
        return 'you';
      }
      return interviewer.fullName || interviewer.email;
    }).join(', ');
    switch (title) {
      case 'Upcoming':
        return <span>{`Scheduled for ${time} with ${interviewers}`}</span>;
      case 'Past':
        return <span>{`Completed ${time} by ${interviewers}`}</span>;
      default:
        return <span>{`Sent ${momentTZ.tz(job.invitedAt, user.timeZone).format(timeFormat)}`}</span>;
    }
  };

  const renderReviewModal = (job) => {
    if (job.interviewers.some((interviewer) => interviewer.id === user.id)) {
      return (
        <ReviewModal meeting={job}>
          <span
            role="button"
            tabIndex={0}
            className="review-link"
          >
            Leave a review
          </span>
        </ReviewModal>
      );
    }
    return null;
  };

  return (
    <div className="meeting-list">
      <div className="heading">
        <span>{`${title} (${meetings.length})`}</span>
      </div>
      {meetings.length > 0 && (
        <List className="content">
          {meetings.map((job) => (
            <List.Item key={job.id}>
              {renderTitle(job)}
              <br />
              {renderTime(job)}
              <br />
              {title === 'Past' && (
                <div>
                  {
                    !job.expert_rating
                      ? renderReviewModal(job)
                      : (
                        <Rating
                          icon="star"
                          rating={job.expert_rating}
                          maxRating={5}
                          disabled
                        />
                      )
                  }
                </div>
              )}
            </List.Item>
          ))}
        </List>
      )}
    </div>
  );
};

const History = ({ expertId }) => {
  const { loading, data } = useQuery(EXPERT_MEETING_HISTORY, {
    variables: { id: expertId },
  });
  if (loading) return <Loading />;

  return (
    <div>
      <MeetingList title="Upcoming" meetings={data.user.upcomingMeetings} />
      <MeetingList title="Past" meetings={data.user.pastMeetings} />
      <MeetingList title="Pending invitations" meetings={data.user.pendingInvites} />
    </div>
  );
};

export default History;
